import * as React from 'react'

const MODE_ICON_UNKNOWN = 'fa-circle-question';
const MODE_ICON_MAP = {
  'bicycle': 'fa-bicycle',
  'automobile': 'fa-car-side',
  'boat': 'fa-ship',
  'train': 'fa-train'
}

export default class TravelMetrics extends React.Component {
  renderTravelMetric(mode, distance) {
    const iconClass = MODE_ICON_MAP[mode] ?? MODE_ICON_UNKNOWN;
    return (<span>{" " + distance.toFixed(2) + " Mi " }<i className={ `fas ${iconClass}` }></i></span>)
  }

  render() {
    // TODO always show bikes first
    const distances = this.props.distances ?? {}
    const modes = Object.keys(this.props.distances).sort()
    return (
      <p className='has-text-grey'>
        { modes.map((mode) => this.renderTravelMetric(mode, distances[mode]))}
      </p>
    )
  }
}
