import * as React from 'react'

import ContentSection from '../components/markdown/ContentSection'
import Map from '../components/map/Map'
import LayoutSection from '../components/layout/LayoutSection'
import ArticlesFull from '../components/articles/ArticlesFull'
import { MediaModalControls } from '../components/modal/MediaModalControls'
import GallerySection from '../components/gallery/GallerySection'

export default class TourTemplate extends React.Component {

  render(){
    const {body, media = [], locationRecordings = [], journals = [], Link } = this.props;

    return (
      <MediaModalControls media={media} >
        <ContentSection body={body} title={"About the Journey"} />
        <LayoutSection id='map-section'>
        <Map recordings={locationRecordings}
            photos={ media } />
        </LayoutSection>
        <ArticlesFull articles={ journals } Link={ Link } />

      </MediaModalControls>
    )
  }

}
