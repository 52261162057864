import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import TravelMetrics from './TravelMetrics';
import Content from '../compat/Content'
import LinkComponent from '../compat/Link'
import { resizeImageData } from '../../util/images'

import {
  articles,
  journalFeatureImage
} from './articles.module.scss'

export default class ArticlesFull extends React.Component {

  resizeFeatureImageData(imageData) {
    const desktopMinPixels = 1024
    const sizes = `(min-width: ${desktopMinPixels}px) 50vw, 100vw`
    return resizeImageData(imageData, sizes);
  }

  render() {
    const Link = this.props.Link || LinkComponent;

      return (
        <div className={`section content ${articles}`}>
            {
              this.props.articles
                .map(node => (
                    <article key={node.id} className="block is-clearfix">
                        {
                        (node.featureImage ?
                            (
                            <figure className={ journalFeatureImage }>
                              <GatsbyImage image={ this.resizeFeatureImageData(node.featureImage.gatsbyImageData) } alt="" />
                                <figcaption>{ node.featureImage.description ? node.featureImage.description : "" }</figcaption>
                            </figure>
                            ) : null)
                        }
                        <h3>
                          <Link to={ node.url }>{node.title}</Link>
                        </h3>
                        <p>{node.displayDateLine}</p>
                        <TravelMetrics distances={ node.distanceTraveled ?? {} } />
                        <Content content={node.parent.body}/>
                    </article>
                ))
            }
        </div>)
    }

}
