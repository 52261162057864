import * as React from 'react'
import PageLayout from '../components/layout/PageLayout'
import Tour from './Tour'
import { graphql, Link } from 'gatsby'

const TourPage = ({data}) => {
  return (
    <PageLayout pageTitle={data.tour.title}
                image={data.tour.featureImage}
                editUrl={data.tour.editUrl} >
    <Tour data={data}
          body={data.tour.parent.body}
          media={data.tour.media}
          locationRecordings={data.tour.locationRecordings}
          journals={ data.tour.pageChildren }
          Link={ Link }
    />
    </PageLayout>
  )
}

export const query = graphql`
query ($id: String) {
  tour(id: {eq: $id}) {
    ...PageMetadata
    ...PageFull
    ...InlinePageChildren
    locationRecordings {
      ...LocationRecordingSummary
    }
    media {
      ...MediaData
    }
  }
}
`

export default TourPage
